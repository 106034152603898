import "./sCheckboxDropdown.css";

document
  .querySelectorAll<HTMLElement>(".s-checkbox-dropdown")
  .forEach((rootElement) => {
    const labelCounterDiv = rootElement.querySelector<HTMLElement>(
      ".s-checkbox-dropdown__label span",
    )!;

    const selectorDiv = rootElement.querySelector<HTMLElement>(
      ".s-checkbox-dropdown__selector",
    )!;

    const selectorTextElement = rootElement.querySelector<HTMLElement>(
      ".s-checkbox-dropdown__selector-text",
    )!;

    const optionsDiv = rootElement.querySelector<HTMLElement>(
      ".s-checkbox-dropdown__options",
    )!;

    const chevronElement =
      selectorDiv.querySelector<HTMLElement>(".fa-chevron-down")!;

    const initialSelectorText = selectorTextElement.textContent;

    updateLabelCounterText();

    optionsDiv.style.display = "none";

    selectorDiv.addEventListener("click", (event) => {
      if (optionsDiv.style.display === "none") {
        optionsDiv.style.display = "block";
        chevronElement.classList.toggle("fa-chevron-down");
        chevronElement.classList.toggle("fa-chevron-up");
      } else {
        optionsDiv.style.display = "none";
        chevronElement.classList.toggle("fa-chevron-down");
        chevronElement.classList.toggle("fa-chevron-up");
      }

      event.stopPropagation();
    });

    optionsDiv.addEventListener("click", (event) => {
      event.stopPropagation();
    });

    document.body.addEventListener("click", (event: MouseEvent) => {
      if (
        !(event.target instanceof Element) ||
        !event.target.classList.contains("selector")
      ) {
        optionsDiv.style.display = "none";
        chevronElement.classList.remove("fa-chevron-up");
        chevronElement.classList.add("fa-chevron-down");
      }
    });

    optionsDiv.addEventListener("change", () => {
      updateSelectorText();
      updateLabelCounterText();
    });

    updateSelectorText();

    function updateLabelCounterText() {
      const totalOptions = optionsDiv.querySelectorAll(
        "input[type='checkbox']",
      ).length;
      const totalSelectedOptions = optionsDiv.querySelectorAll(
        "input[type='checkbox']:checked",
      ).length;

      labelCounterDiv.textContent = `(${totalSelectedOptions}/${totalOptions})`;
    }

    function updateSelectorText() {
      const checkedCheckboxes = Array.from(
        optionsDiv.querySelectorAll('input[type="checkbox"]:checked'),
      );

      const checkedLabels = checkedCheckboxes.map((checkbox) => {
        const label = checkbox.nextElementSibling as HTMLElement;

        return (label.textContent || label.innerText).trim();
      });

      const newTextContent = checkedLabels.join(", ");
      selectorTextElement.textContent = newTextContent || initialSelectorText;
    }
  });
